//@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@font-face {
  font-family: 'Margarosa';
  src: local('Margarosa'), url(../../../public/fonts/Margarosa.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

body{
  background-image: url('../../assets/lightBackground.jpg');
  background-size: cover;
}

/*COLOR VARIABLES*/

$clr-primary: #ffc30c;
$clr-primary-hover: #29E6A7;
$clr-primary-dark: #039D69;
$clr-gray100: #F9FBFF;
$clr-gray150: #F4F6FB;
$clr-gray200: #EEF1F6;
$clr-gray300: #E1E5EE;
$clr-gray400: #767B91;
$clr-gray500: #4F546C;
$clr-gray600: #2A324B;
$clr-gray700: #161D34;

/*BORDER RADIUS*/

$radius: 0.2rem;

*,
*::before,
*::after {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.card {
  overflow: hidden;
  box-shadow: 0 2px 20px $clr-gray300;
  border-radius: $radius;
  display: flex;
  width: 260px;
  height: 430px;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  transition: transform 200ms ease-in;
  margin-bottom: 50px;
  background-color: white;


  &__image {
    height: 12rem;
    width: 80%;
    // margin-left: 30px;

  }

  &__title {
    padding: 1rem;
    margin-top: 0px;
    font-family: 'Margarosa';
  }

  &__btn {
    cursor: pointer;
    padding: 1rem;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
    border: 2px solid;
    background: transparent;
    margin-top: 0px;
    outline: none;
    border-radius: $radius;
    transition: background 200ms ease-in, color 200ms ease in;
  }

  &:hover {
    transform: scale(1.02);
  }


  &:hover .card__btn {

    color: #FFF;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1280px) {
  .card {
    width: 290px;
  }

  .card__title {
    padding: 0;
  }
}