body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }

  .aboutContainer{
    // margin-bottom: 300px !important;
  }

    .aboutBlock {
      background-color: #ffc30c;
      border-radius: 15px;
      margin-bottom: 150px !important;
      font-size: 20px;
      text-align:center;
      padding: 12px;
      max-width: 90%;

    }

    .aboutBlock:hover {
        background-color: #ffc30c;
        border-radius: 15px;
        margin-bottom: 20px;
        font-size: 20px;
        text-align: justify;
        padding: 12px;
        color: black;
    }


    @media only screen and (max-width: 1099px) {
        .aboutContainer{
            .top{
                flex-direction: column !important;
                width: 100%;
                padding-top: 20px;
                
                .top-left{
                    width: 100% !important;
                    align-items: center !important;
                    justify-content: center !important;
    
                    .welcome-text {
                        width: 90% !important;
                        justify-content: center;
                        align-items: center !important;
                    }
                }
    
                .top-right{
                    width: 100% ;
    
                    img {
                        width: 100% !important;
                    }
                }
                
            }
            
        }
    }

    a {
        text-decoration: none; 
        color: inherit;
      }
      
      
      a:hover {
        color: inherit;
        text-decoration: none;
      }
      
    
    @media only screen and (max-width: 1099px) {
        .aboutBlock{
            margin-bottom: 200px !important;
        }
    }

    
